import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiPhoneCall } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";

import "./PopupForm.css"; // Include CSS for sliding and popup styling

const PopupForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);

  // Automatically open the popup after 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 3000); // 2 seconds delay

    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  // Function to handle "Book Now" button click
  const handleBookNow = () => {
    setShowForm(true); // Display the Google Form
  };

  const closePopup = () => {
    setIsOpen(false);
    setShowForm(false); // Reset form display when closing
  };

  return (
    <>
      {isOpen && (
        <div className={`popup-overlay ${isOpen ? "slide-in" : "slide-out"}`}>
          <div className="popup-content">
            <button className="close-popup-btn" onClick={closePopup}>
              &times;
            </button>
            {!showForm ? (
              <>
                <h2>Book Your Service Now!</h2>
                <p>
                  Get doorstep pickup and 200 days of warranty on all services.
                </p>
                <a href="https://wa.me/919500188486?text=Hello%20I%20would%20like%20to%20know%20more%20about%20your%20services">
                  <FaWhatsapp style={{ width:'50px',height:'50px', color:"white", paddingRight:'15px'}}></FaWhatsapp>
                </a>
                <a
                  style={{ marginRight: "15px"  }}
                  className="book-now-btn"
                  href="tel:+919500188486"
                >
                  <i className="icon" style={{ paddingRight: "10px" }}>
                    <FiPhoneCall />
                  </i>
                  95001 88486
                </a>
                <button className="book-now-btn" onClick={handleBookNow}>
                  Book Now
                </button>
              </>
            ) : (
              <>
                <h2>Fill Out the Form</h2>
                <a href="https://wa.me/919500188486?text=Hello%20I%20would%20like%20to%20know%20more%20about%20your%20services">
                  <FaWhatsapp style={{ width:'50px',height:'50px', color:"white", paddingRight:'10px'}}></FaWhatsapp>
                </a>
                <a className={`book-now-btn`} href="tel:+919500188486">
                  <i className="icon" style={{ paddingRight: "10px" }}>
                    <FiPhoneCall />
                  </i>
                  95001 88486
                </a>
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSeISXro51wB1bjNEiD8ZoBV5XYWVUK67YBSrL7E6KNhsqrGCw/viewform?embedded=true"
                  width="100%"
                  height="500"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                >
                  Loading…
                </iframe>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PopupForm;
