import React from "react";
import Typed from "react-typed";
import {Link} from "react-router-dom";


const AboutOne = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                src="./images/about/about-1.png"
                alt="About Images"
              />
            </div>
          </div>

          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h2 className="title">
                Our Services <br />{" "}
                  <Typed
                    className="theme-gradient"
                    strings={["Laptop Repair.", "Doorstep Pickup.", "Printer Service.", "Tech Support","Quick Turnaround"]}
                    typeSpeed={80}
                    backSpeed={5}
                    backDelay={1000}
                    loop
                  />
                </h2>
                <p>
                  At ASKTECH Solutions, we are committed to delivering
                  top-quality laptop and printer services. With expertise in
                  chip-level repairs and a focus on precision, our skilled
                  technicians handle everything from hardware fixes to software
                  troubleshooting. We understand the importance of your devices,
                  which is why we offer fast, reliable service to get you back
                  up and running in no time.
                </p>
                <p>
                  Convenience is key, and we bring our services right to your
                  door with our hassle-free pickup and delivery options. Whether
                  you’re a business or an individual, we cater to all your tech
                  needs with professionalism and care. Your satisfaction is our
                  priority, and we strive to exceed your expectations with every
                  service we provide.
                </p>
                <div className="read-more-btn mt--40">
                  <Link className="btn-default" to="/about-us">
                    <span>More About Us</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
